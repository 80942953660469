import revive_payload_client_yixgYhW1sz from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._c6clhaplstng5w6lg23vhfzyia/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_g7VPz08Zni from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._c6clhaplstng5w6lg23vhfzyia/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ujRuvCZnI5 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._c6clhaplstng5w6lg23vhfzyia/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_d8KRlFNJEf from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._c6clhaplstng5w6lg23vhfzyia/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_F4TC4o5W7B from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._c6clhaplstng5w6lg23vhfzyia/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_e61y2pEKT7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._c6clhaplstng5w6lg23vhfzyia/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Uhxt1nXOke from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._c6clhaplstng5w6lg23vhfzyia/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_qUJv6dzic7 from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.27.2_typescript@5.6.3_vue@3.5.13_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_4SqioGUwt7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_eslint@9.15.0_jiti@2.4.0__ioredis@5.4._c6clhaplstng5w6lg23vhfzyia/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_JFduVUWDN6 from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.27.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/vercel/path0/.nuxt/unocss.mjs";
import theme_client_cHveFBxNFz from "/vercel/path0/node_modules/.pnpm/@una-ui+nuxt-edge@0.28.0-beta.1-28857457.95ab69c_@unocss+preset-wind@0.63.6_@unocss+webpack@0_yqa6uzyuxwkufe5dv4sa3qgl6q/node_modules/@una-ui/nuxt-edge/dist/runtime/plugins/theme.client.js";
import analytics_client_mtqVw3YRXk from "/vercel/path0/plugins/analytics.client.ts";
export default [
  revive_payload_client_yixgYhW1sz,
  unhead_g7VPz08Zni,
  router_ujRuvCZnI5,
  payload_client_d8KRlFNJEf,
  navigation_repaint_client_F4TC4o5W7B,
  check_outdated_build_client_e61y2pEKT7,
  chunk_reload_client_Uhxt1nXOke,
  plugin_vue3_qUJv6dzic7,
  components_plugin_KR1HBZs4kY,
  prefetch_client_4SqioGUwt7,
  plugin_client_JFduVUWDN6,
  unocss_MzCDxu9LMj,
  theme_client_cHveFBxNFz,
  analytics_client_mtqVw3YRXk
]